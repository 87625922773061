const form_data = {
  'first_name': '', 
  'last_name': '', 
  'mobile_no': '', 
  // 'email': '', 
  'address_1': '',
  // 'address_2': '',
  'city': '',
  'province': '',
  'branch_id': '',
  // 'appliance_type': '',
  // 'complaint': '',
  // 'brand': '',
  // 'model_no': '',
  // 'warranty_status': 'expired',
  // 'serial_no': '',
  // 'purchased_date': '', 
  // 'location': '',
  // 'proof_of_purchase': '',
  // 'proof_of_purchase_url':'',
  // 'unit_details': '',
  // 'unit_details_url':'',
  // 'unit_defect': '',
  // 'unit_defect_url':'',
}

const store = {
  form_data
}

export default store
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import '../../assets/css/styles.css';//css
import Swal from 'sweetalert2';
import store from '../../helpers/store';

import InputText from '../elements/InputText'
import InputTextArea from '../elements/InputTextArea'
import InputNumber from '../elements/InputNumber';
import Loader from '../parts/Loader'
import RenderErrors from '../elements/RenderErrors';
import helpers from '../../helpers/helpers';
import { httpFormRequest, httpRequest } from '../../helpers/httpService';
import ReCAPTCHA from "react-google-recaptcha"; //2022 march
import DropDownSelection from '../elements/DropDownSelection';
 
function HomePage ({readonly = false}){
    let { service_id } = useParams();
    const wpRoot = window.location.host;
    const queryString = window.location.search ?? '';
    const [campaigns, setCampaigns] = useState(store.camapaigns)
    const [is_loaded, setLoaded] = useState(0)
    const [form_data, setForm] = useState(store.form_data);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(0);
    const recaptchaRef = useRef(null);
    const [branch_list, setBranches] = useState([]);
    
   
    useEffect(() => {
        //getCMS()
        getBranches()
    }, []);

    useEffect(() => {
        //getCMS()
        console.log(branch_list)
    }, [branch_list]);
    function inputChange(e) {
        let { value, name } = e.target;

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                value = 1;

                if(name == 'warranty_status') value = 'active'
            } else {
                value = 0;

                if(name == 'warranty_status') value = 'expired'
            }
        }
        else if (e.target.type === "file") {
          value = e.target.files[0]
        }

        let new_state = { [name]: value }

        setForm({ ...form_data, ...new_state });
    }

    function getFormData(status = 'draft'){
        let form = new FormData();

        for(var key in form_data) {
            form.append(key, form_data[key]);
        }

        return form
    }

    function resetForm(){
        setForm(store.form_data);
        setErrors([])
    }

    function getBranches(){
        const url = helpers.getUrl('public/branches')
        setLoading(1)

        httpRequest(url, "GET", {}, false)
            .then((res) => {
                setBranches(res.data)
                setLoading(0)
            })
            .catch((err) => {
                setErrors({"branch_id": ["Unable to load branch list."]})
                setLoading(0)
            }); 
    }


    function submit(e){
        const captcha = recaptchaRef.current.getValue();

        if(!captcha){
            setErrors( {
                recaptcha: ['The recaptcha is required.']
            } );
            return;
        }

        const formData = getFormData()
        let url = helpers.getUrl('public/service-request')

        recaptchaRef.current.reset() //Reinitialize reCaptcha
        setLoading(1)

        httpFormRequest(url, "POST", {
            postdata: formData,
        }, true)
        .then((res) => {
            setLoading(0)
            resetForm()
            
            let message = 'Service request created.'
            if(res.data){
                message += ` Reference no: ${res.data.service_no}.`
            }

            Swal.fire({
                title: "Success!",
                text: message,
                icon: "success",
                confirmButtonText: "OK",
            });
        })
        .catch((err) => {
            console.log(err)
            setLoading(0)
            
            if(err.data){
                setErrors( err.data );
            }else{
                setErrors([])
            }

            Swal.fire({
                text: err.message,
                icon: "error",
                confirmButtonText: "OK",
            });
        }); 
    }

    function removeErrors(e){
        let { name } = e.target;
        let newErrors = {...errors}

        delete newErrors[name];

        setErrors(newErrors);
    }
    
    return (
        <div style={{backgroundColor: '#f7f7f7'}}>
            <div className="container">
                <div className="row d-flex justify-content-center pt-5 pb-5">
                    <div className="col-md-12 col-lg-7" style={{position: 'relative'}}>
                        <Loader isLoading={loading}/>
                        
                        <h4 className='mb-4'>New Service Request</h4>

                        <form>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 mb-4">
                                            <h5 className='mb-0'>Personal Information</h5>
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            <DropDownSelection
                                                tagClass="form-select"
                                                name='branch_id' 
                                                placeholder='Select Branch'
                                                label={<>Nearest Branch To Me</>}
                                                value={form_data.branch_id}  
                                                onChange={inputChange}
                                                optionList={branch_list}
                                                optionProps ={{value:'id', label:'name'}}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="branch_id" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className='col-12'></div>
                                        <div className="col-md-6 form-group mb-3">
                                            <InputText
                                                id="first_name" 
                                                name="first_name" 
                                                value={form_data.first_name}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="Ex. Juan"
                                                type="text"
                                                label={<><span className='text-danger fw-bold'>*</span> First Name</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="first_name" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            <InputText
                                                id="last_name" 
                                                name="last_name" 
                                                value={form_data.last_name}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="Ex. Dela Cruz"
                                                type="text"
                                                label={<><span className='text-danger fw-bold'>*</span> Last Name</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="last_name" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            <InputNumber
                                                id="mobile_no" 
                                                name="mobile_no" 
                                                value={form_data.mobile_no}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="Mobile No."
                                                type="text"
                                                label={<><span className='text-danger fw-bold'>*</span> Mobile No.</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="mobile_no" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-12 form-group mb-3">
                                            <InputTextArea
                                                id="address_1" 
                                                name="address_1" 
                                                label={<><span className='text-danger fw-bold'>*</span> Address Line 1</>}
                                                value={form_data.address_1}  
                                                tagClass="form-control"
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="Apartment, House No etc."
                                                rows={1}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="address_1" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        {/* <div className="col-md-12 form-group mb-3">
                                            <InputTextArea
                                                id="address_2" 
                                                name="address_2" 
                                                label="Address Line 2"
                                                value={form_data.address_2}  
                                                tagClass="form-control"
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="Street No, Barangay, District"
                                                rows={1}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="address_2" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div> */}
                                        <div className="col-md-6 form-group mb-3">
                                            <InputText
                                                id="city" 
                                                name="city" 
                                                value={form_data.city}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="Municipality/City"
                                                type="city"
                                                label={<><span className='text-danger fw-bold'>*</span> City</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="city" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <InputText
                                                id="province" 
                                                name="province" 
                                                value={form_data.province}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="Province"
                                                type="province"
                                                label={<><span className='text-danger fw-bold'>*</span> Province</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="province" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                    </div>
                                </div>                                   
                            {/* </div>
                            <div className="card mb-3"> */}
                                {/* <div className="card-body">
                                    <div className="row pb-3">
                                        <div className="col-12 mb-2">
                                            <h5 className='mb-0'>Appliance Information</h5>
                                        </div>
                                        <div className="col-12"></div>
                                        <div className="col-12 mt-3">
                                            <div className='row border ms-0 me-0 rounded' style={{padding: '0.5rem 0'}}>
                                                <div className="col-sm-6 col-lg-3 mb-0 form-group">
                                                    <div className="form-check" style={{ paddingLeft: '0'}}>
                                                    <InputRadio
                                                        id="warranty_status_1"
                                                        name="warranty_status" 
                                                        value="active" 
                                                        checked = {form_data.warranty_status == 'active'}
                                                        label="In-Warranty"
                                                        tagClass="form-control" 
                                                        onChange={inputChange}
                                                    />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-lg-3 form-group mb-0">
                                                    <div className="form-check" style={{ paddingLeft: '0'}}>
                                                    <InputRadio
                                                        id="warranty_status_2"
                                                        name="warranty_status" 
                                                        value="expired" 
                                                        checked = {form_data.warranty_status == 'expired'}
                                                        label="Out-Warranty"
                                                        tagClass="form-control" 
                                                        onChange={inputChange}
                                                    />
                                                    </div>

                                                </div>

                                            </div>
                                            <RenderErrors 
                                                errors={errors} 
                                                name="warranty_status" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-12" />
                                        <div className="col-md-6 form-group mt-3">
                                            <InputText
                                                id="appliance_type" 
                                                name="appliance_type" 
                                                value={form_data.appliance_type}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="TV, Refregerator, Aircondition etc."
                                                type="text"
                                                label={<><span className='text-danger fw-bold'>*</span> Appliance Type</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="appliance_type" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group mt-3">
                                            <InputText
                                                id="brand" 
                                                name="brand" 
                                                value={form_data.brand}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="Brand"
                                                type="text"
                                                label={<><span className='text-danger fw-bold'>*</span> Brand</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="brand" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group mt-3">
                                            <InputText
                                                id="purchased_date" 
                                                name="purchased_date" 
                                                value={form_data.purchased_date}
                                                tagClass="form-control mb-0"
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="Date"
                                                type="date"
                                                // label="Date of Purchased"
                                                label={<><span className='text-danger fw-bold'>*</span> Date of Purchased</>}
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="purchased_date" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-md-6 form-group mt-3">
                                            <InputText
                                                id="location" 
                                                name="location" 
                                                tagClass="form-control mb-0"
                                                value={form_data.location}  
                                                onChange={inputChange}
                                                onFocus={removeErrors}
                                                placeholder="Location"
                                                type="text"
                                                label="Unit Location"
                                                readOnly={readonly}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="location" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>


                                        {
                                            form_data.warranty_status=='active'
                                            ? 
                                            <>
                                                <div className="col-md-6 form-group mt-3">
                                                    <InputFile
                                                        id="proof_of_purchase" 
                                                        name="proof_of_purchase" 
                                                        tagClass="form-control"
                                                        label={<><span className='text-danger fw-bold'>*</span> Proof of Purchase</>}
                                                        onChange={inputChange}
                                                        onFocus={removeErrors}
                                                        type="file"
                                                        accept="image/*"
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="proof_of_purchase" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                                <div className="col-md-6 form-group mt-3">
                                                    <InputFile
                                                        id="unit_details" 
                                                        name="unit_details" 
                                                        tagClass="form-control"
                                                        label={<><span className='text-danger fw-bold'>*</span> Unit Details</>}
                                                        onChange={inputChange}
                                                        onFocus={removeErrors}
                                                        type="file"
                                                        accept="image/*"
                                                    />
                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="unit_details" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                                <div className="col-md-6 form-group mt-3 mb-0">
                                                    <InputFile
                                                        id="unit_defect" 
                                                        name="unit_defect" 
                                                        tagClass="form-control"
                                                        label={<><span className='text-danger fw-bold'>*</span> Unit Defective</>}
                                                        onChange={inputChange}
                                                        onFocus={removeErrors}
                                                        type="file"
                                                        accept="image/*"
                                                    />

                                                    <RenderErrors 
                                                        errors={errors} 
                                                        name="unit_defect" 
                                                        className="text-danger"
                                                        style={{fontSize: '.9rem'}}
                                                    />
                                                </div>
                                            </> : ''
                                        }
                                    </div>
                                </div> */}
                                <div className="card-footer border-top-0">
                                    <div className="row">
                                        <div className="col-lg-8 mb-2 mt-2">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey="6LdmdwwpAAAAALXqUgq-F9Upm120UUepV-ohMlY9"
                                                onChange={()=>removeErrors({target: {name:'recaptcha'}})}
                                            />
                                            <RenderErrors 
                                                errors={errors} 
                                                name="recaptcha" 
                                                className="text-danger"
                                                style={{fontSize: '.9rem'}}
                                            />
                                        </div>
                                        <div className="col-lg-4 d-flex align-items-center justify-content-end pt-2 pb-2">
                                            <button type='button' className='btn btn-success' onClick={submit}><i className="fas fa-save mr-2" />{service_id ? 'Update' : 'Save'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default HomePage;
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';

// import '../src/assets/js/slick-1.8.1/slick/slick'
/**
 * Parts
 */
import Header from './components/parts/Header';
import Footer from './components/parts/Footer';
/**
 * Main Router
*/
import MainRoutes from './routes/Routes';

function App() {

    useEffect(() => {
          
        $(function($){
            $(".menu-icon, .menu-close").click(function(){
                $("#side-menu").animate({'opacity': 'toggle'});
                return false;
            });

            var header = $("#top-header");
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();

                if (scroll >= 100) {
                    //header.addClass("fixed");
                } else {
                    //header.removeClass("fixed");
                }
            });
        });

    }, []);

  return (
      <div className="App">
          <Header />

          <MainRoutes />{/* All pages can be manage inside this components */}

          <Footer />
      </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import images from '../../helpers/images';

import httpService from '../../helpers/httpService';

function Footer(){

    return (
        <div id="footer-section">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <ul className="sm-icons">
                    <li><a href="#" target="_new"><img src={images.facebookIcon} alt="fb-icon" /></a></li>
                    <li><a href="#" target="_new"><img src={images.twitterIcon} alt="twitter-icon" /></a></li>
                    <li><a href="#" target="_new"><img src={images.youtubeIcon} alt="youtube-icon" /></a></li>
                    <li><a href="#" target="_new"><img src={images.linkedinIcon} alt="linkedin-icon" /></a></li>
                    <li><a href="#" target="_new"><img src={images.instagramIcon} alt="instagram-icon" /></a></li>
                    </ul>
                    <p>© Copyright {new Date().getFullYear()} JMA Electronics.</p>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;

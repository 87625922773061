// import moment from 'moment';
import images from './images';
import appConfig from '../config/env';
import { Link } from "react-router-dom";

const getToken = () => {
    if (localStorage.getItem('jma-servicehub-token')) {
        return localStorage.getItem('jma-servicehub-token')
    }

    return false
}

const isAuthenticated = () => {
    if (localStorage.getItem('jma-servicehub-authenticated')) {
        return true
    }

    return false
}

const getUserName = () => {
    if (localStorage.getItem('jma-servicehub-name')) {
        return localStorage.getItem('jma-servicehub-name')
    }

    return false
}

const getPermissions = () => {
    if (localStorage.getItem('jma-servicehub-permissions')) {
        return localStorage.getItem('jma-servicehub-permissions')
    }

    return []
}

const getUrl = (slug = '') => {
    const url = appConfig.apiUrl + `/${slug}`;

    return url
}

function displayStatusBadge(status = ''){
    let label = '';
    let class_name = ''

    switch (status) {
        case 'created':
            class_name = "badge bg-primary"
            break;
        case 'for-assessment':
            class_name = "badge bg-info"
            break;
        case 'unit-checked':
            class_name = "badge bg-info"
            break;
        case 'pending-quoation':
            class_name = "badge bg-secondary"
            break;
        case 'approved-quoation':
            class_name = "badge bg-info"
            break;
        case 'in-progress':
            class_name = "badge bg-warning"
            break;
        case 'repair-completed':
            class_name = "badge bg-success"
            break;
        case 'for-release':
            class_name = "badge bg-success"
            break;
        case 'released':
            class_name = "badge bg-success"
            break;
        case 'completed':
            class_name = "badge bg-secondary"
            break;
        case 'cancelled':
            class_name ="badge bg-danger"
            break;
        default:
            return;
    }

    label = <span className={class_name}>{displayStatus(status)}</span>

    return label;
}

function displayStatus(status = ''){
    let label = '';

    switch (status) {
        case 'created':
            label = 'Created'
            break;
        case 'for-assessment':
            label = 'For Assessment'
            break;
        case 'unit-checked':
            label = 'Unit Checked'
            break;
        case 'pending-quoation':
            label = 'Pending Quoation'
            break;
        case 'approved-quoation':
            label = 'Approved Quoation'
            break;
        case 'in-progress':
            label = 'In Progress'
            break;
        case 'repair-completed':
            label = 'Repair Completed'
            break;
        case 'for-release':
            label = 'For Release'
            break;
        case 'released':
            label = 'Unit Released'
            break;
        case 'completed':
            label = 'Completed'
            break;
        case 'cancelled':
            label = 'Cancelled'
            break;
        default:
            return;
    }

    return label;
}

function ucwords(str){
    if(!str) return;
    str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });

    return str;
}

function getFormatAmount(amount){
    if(!amount) return '0.00'

    let total = amount;
    var num_parts = amount.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "");
    total = num_parts.join(".");
    if(!isNaN(total)){
        total = Number(total).toFixed(2)
    }
    total = total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return total;
}

const helpers = {
    getToken,
    isAuthenticated,
    getUserName,
    getPermissions,
    getUrl,
    displayStatusBadge,
    ucwords,
    getFormatAmount
}

export default helpers;
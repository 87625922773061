import mainLogo from '../assets/img/jma-electronics-logo.webp';
import burgerIcon from '../assets/img/burger-icon.svg';
import closeIcon from '../assets/img/menu-close.svg';

import facebookIcon from '../assets/img/sm-icon-01.svg';
import twitterIcon from '../assets/img/sm-icon-02.svg';
import youtubeIcon from '../assets/img/sm-icon-03.svg';
import linkedinIcon from '../assets/img/sm-icon-04.svg';
import instagramIcon from '../assets/img/sm-icon-05.svg';

//import multiColorLine from '../assets/img/levelup-multi-color-line.png'

// import campaign_start_up from '../assets/img/levelup-start-up.jpg'
// import campaign_wash_up from '../assets/img/levelup-wash-up.jpg'
// import campaign_study_up from '../assets/img/levelup-study-up.jpg'
// import campaign_stand_up from '../assets/img/levelup-stand-up.jpg'
// import campaign_childhood_rescue from '../assets/img/levelup-childhood-rescue.jpg'
// import campaign_dapat_handa from '../assets/img/levelup-dapat-handa.jpg'

const images = {
    mainLogo,
    burgerIcon,
    closeIcon,
    facebookIcon,
    twitterIcon,
    youtubeIcon,
    linkedinIcon,
    instagramIcon,
    //multiColorLine,
    // campaign_start_up,
    // campaign_wash_up,
    // campaign_study_up,
    // campaign_stand_up,
    // campaign_childhood_rescue,
    // campaign_dapat_handa
}

export default images
import React, { useEffect, useState } from 'react';
import { Link  } from "react-router-dom";
import helpers from '../../helpers/helpers';
import { httpFormRequest } from '../../helpers/httpService';
import moment from 'moment';
import Swal from 'sweetalert2';
 
function TrackTracePage (){
    const [form_data, setForm] = useState({
        'service_no' : '',
        'last_name': ''
    });
    const [loading, setLoading] = useState(0);
    const [history, setHistory] = useState('');

    function inputChange(e) {
        let { value, name } = e.target;
        let new_state = { [name]: value }
        setForm({ ...form_data, ...new_state });
    }

    function getHistory(){
        const params = '?service_no='+form_data.service_no+'&last_name='+form_data.last_name;
        let url = helpers.getUrl(`public/service-request/track${params}`)

        setLoading(1)

        httpFormRequest(url, "GET", {})
            .then((res) => {
                console.log(res)
                displayHistory(res)
                setLoading(0)
            })
            .catch((err) => {
                setLoading(0)
                setHistory('')
                Swal.fire({
                    text: err.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }); 
    }

    function displayUnitCheckStatus(data){
        if(!data) return;

        let suffix = ''

        if(data.action=='unit-checked')
        {
            suffix = `: ${data.assessment}`
        }

        return suffix;
    }

    function displayHistory(history){
            
        let list_items = [];
        if (Object.keys(history).length > 0) {
            list_items = Object.entries(history).map(([key, data]) => {
                return (<div className="card mb-2">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3 fst-italic">{moment(data.updated_at).format('DD-MMM-YYYY')}</div>
                                    <div className="col-md-9">{getFunctionLabel(data.action)}{displayUnitCheckStatus(data)}</div>
                                </div>
                            </div>
                        </div>)
            })
        }
        else{
            list_items =   <div class="alert alert-info" role="alert">Service Request Not Found.</div>

        }

        setHistory(list_items)
    }

    function getFunctionLabel(action = ''){
        let text = "";
    
        switch (action) {
            case "created":
                text = "Created";
                break;
            case "created-assessment-schedule":
                text = "Added Assessment Schedule";
                break;
            case "updated-assessment-schedule":
                text = "Updated Assessment Schedule";
                break;
            case "unit-checked":
                text = "Unit Checked";
                break;
            case "unit-checked-update":
                text = "Updated Unit Checked";
                break;
            case "pending-quoation":
                text = "Quotation Pending Approval";
                break;
            case "approved-quoation":
                text = "Quotation Approved";
                break;
            case "in-progress":
                text = "Service in Progress";
                break;
            case "repair-completed":
                text = "Repair Completed";
                break;
            case "for-release":
                text = "Ready for Release";
                break;
            case "completed":
                text = "Completed";
                break;
            case "return-to-owner":
                text = "Return To Owner";
                break;
            case "cancelled":
                text = "Cancelled";
                break;
            default:
                text = "";
                break;
        }
    
        return text;
    }

    return (
        <div style={{backgroundColor: '#f7f7f7', minHeight: '700px'}}>
                <div className="container">
                    <div className="row d-flex justify-content-center pt-5 pb-5">
                        <div className="col-md-10 col-lg-6 mb-4" style={{position: 'relative'}}>
                            <h4 className='text-center mb-3'>Track Service Request</h4>
                            {/* <div className="input-group mb-3 p-3">
                                <input type="text" className="form-control" placeholder="Enter Service Reference No." onChange={inputChange} value={service_no}  disabled={loading}/>
                                <button className="btn btn-outline-info" type="button" id="button-addon2" onClick={getHistory}  disabled={loading}>{loading ? 'Checking...':'Track'}</button>
                            </div> */}


                            <div className="card">
                                <div className="card-body d-flex flex-column justify-content-center align-items-center">   
                                    <input type="text" name="last_name" className="form-control mb-2" placeholder="Enter Last Name" onChange={inputChange} value={form_data.last_name}  disabled={loading}/>        
                                    <input type="text" name="service_no" className="form-control mb-3" placeholder="Enter Reference No." onChange={inputChange} value={form_data.service_no}  disabled={loading}/>

                                    <div>
                                    <button className="btn btn-outline-info" type="button" id="button-addon2" onClick={getHistory}  disabled={loading}>{loading ? 'Checking...':'Track'}</button>
                                    </div>

                                    {history ? <Link className='btn btn-primary btn-sm mt-3' to={'/job-order?ServiceId='+form_data.service_no}>View Job Order</Link> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-12"></div>
                        <div className="col-lg-6">
                            {history}
                        </div>
                    </div>
                </div>
        </div>
    )
}
 
export default TrackTracePage;
import React from 'react';
import { Outlet, Link } from "react-router-dom";
import images from '../../helpers/images';

function Header(){
    const wpRoot = window.location.host;
    const queryString = window.location.search ?? '';
    
    return (
        <>
            <div id="top-header">
                <div className="container">
                    <div className="row align-items-center desktop-menu">
                        <div className="col-md-3">
                            <Link to="/"><img src={images.mainLogo} alt="main-logo" className="brand-logo" /></Link>
                        </div>
                        <div className="col-md-3 header-cta-btn">
                            {/* <Link className="orange-btn" to={url} target='_blank'>GIVE TODAY</Link> */}
                        </div>
                        <div className="col-md-6 text-end">
                            <ul className="top-menu">
                                <li><Link to="/">Service Request</Link></li>
                                <li><Link to="track">Track &amp; Trace</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row align-items-center mobile-menu">
                        <div className="col-12">
                            <a href="#" className="menu-icon">
                                <img src={images.burgerIcon} alt="menu-icon" />
                            </a>
                            <img src={images.mainLogo} alt="main-logo" className="brand-logo" />
                        </div>
                    </div>
                </div>
            </div>


            <div id="side-menu">
                <Link className='menu-close' to="/">
                    <img src={images.closeIcon} alt="menu-close" />
                </Link>
                <img src={images.mainLogo} alt="main-logo" className="brand-logo" />
                <ul className="top-menu">
                    <li><Link to="/">Service Request</Link></li>
                    <li><Link to="track">Track &amp; Trace</Link></li>
                </ul>
                {/* <Link className="orange-btn" to="https://servicehub.jma-electronics.com/" target='_blank'>GIVE TODAY</Link> */}
            </div>


            <Outlet />
        </>
    )
}

export default Header;